import React, { useState, useEffect } from 'react';
import { getSupabase } from '../config/supabase';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export function RSVPList({ weddingId, rsvpData }) {
  const [guests, setGuests] = useState(rsvpData || []);
  const [filter, setFilter] = useState('all');
  const { t } = useTranslation();
  const [expandedGuest, setExpandedGuest] = useState(null);
  const supabase = getSupabase();

  const toggleGuestDetails = (guestId) => {
    setExpandedGuest(expandedGuest === guestId ? null : guestId);
  };

  const filteredGuests = guests.filter(guest => {
    if (filter === 'all') return true;
    return guest.rsvp_status === filter;
  });

  const guestCounts = {
    all: guests.length,
    invited: guests.filter(guest => guest.rsvp_status === 'invited').length,
    rsvp_yes: guests.filter(guest => guest.rsvp_status === 'rsvp_yes').length,
    rsvp_no: guests.filter(guest => guest.rsvp_status === 'rsvp_no').length,
    confirmed: guests.filter(guest => guest.rsvp_status === 'confirmed').length,
  };

  const updateGuestStatus = async (guestId, newStatus) => {
    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .update({ rsvp_status: newStatus })
        .eq('id', guestId);

      if (error) throw error;

      setGuests(guests.map(guest => 
        guest.id === guestId ? { ...guest, rsvp_status: newStatus } : guest
      ));

      toast.success(t('components.rsvpList.statusUpdateSuccess'));
    } catch (error) {
      console.error('Error updating guest status:', error);
      toast.error(t('components.rsvpList.statusUpdateError'));
    }
  };

  return (
    <div className="mt-4 px-2 sm:px-0">
      <div className="mb-4 flex flex-wrap gap-2 justify-center">
        {['all', 'invited', 'rsvp_yes', 'rsvp_no', 'confirmed'].map((status) => (
          <button
            key={status}
            onClick={() => setFilter(status)}
            className={`px-3 py-1 rounded-md text-xs font-medium transition-colors duration-200 ${
              filter === status
                ? 'bg-gray-800 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
            }`}
          >
            {t(`components.rsvpList.${status === 'all' ? 'all' : status === 'invited' ? 'invited' : status === 'rsvp_yes' ? 'attending' : status === 'rsvp_no' ? 'notAttending' : 'confirmed'}`)} ({guestCounts[status]})
          </button>
        ))}
      </div>
      <ul className="space-y-4">
        {filteredGuests.map((guest) => (
          <li key={guest.id} className="border p-3 rounded shadow-sm hover:shadow-md transition-shadow duration-200">
            <div 
              className="flex flex-col justify-between items-start cursor-pointer" 
              onClick={() => toggleGuestDetails(guest.id)}
            >
              <div className="mb-2 w-full">
                <strong className="text-base block">{guest.full_name}</strong>
                <span className="block text-xs text-gray-600">{guest.email}</span>
              </div>
              <div className={`px-2 py-1 rounded-full text-xs ${
                guest.rsvp_status === 'invited' ? 'bg-blue-100 text-blue-800' :
                guest.rsvp_status === 'rsvp_yes' ? 'bg-green-100 text-green-800' : 
                guest.rsvp_status === 'rsvp_no' ? 'bg-red-100 text-red-800' : 
                'bg-yellow-100 text-yellow-800'
              }`}>
                {guest.rsvp_status === 'invited' ? t('components.rsvpList.invited') :
                 guest.rsvp_status === 'rsvp_yes' ? t('components.rsvpList.attending') : 
                 guest.rsvp_status === 'rsvp_no' ? t('components.rsvpList.notAttending') : 
                 t('components.rsvpList.confirmed')}
              </div>
            </div>
            {expandedGuest === guest.id && (
              <div className="mt-3 text-xs text-gray-700">
                <p><strong>{t('components.rsvpList.phone')}:</strong> {guest.cellphone || t('components.rsvpList.notProvided')}</p>
                <p><strong>{t('components.rsvpList.numberOfGuests')}:</strong> {guest.number_of_guests || 1}</p>
                <p><strong>{t('components.rsvpList.dietaryRestrictions')}:</strong> {guest.dietary_restrictions || t('components.rsvpList.none')}</p>
                <p><strong>{t('components.rsvpList.additionalNotes')}:</strong> {guest.additional_notes || t('components.rsvpList.none')}</p>
                <p><strong>{t('components.rsvpList.rsvpDate')}:</strong> {new Date(guest.rsvp_date).toLocaleDateString()}</p>
                <div className="mt-2 flex flex-wrap gap-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'invited');
                    }}
                    className="px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markInvited')}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'rsvp_yes');
                    }}
                    className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markAttending')}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'rsvp_no');
                    }}
                    className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markNotAttending')}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateGuestStatus(guest.id, 'confirmed');
                    }}
                    className="px-2 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors duration-200 text-xs"
                  >
                    {t('components.rsvpList.markConfirmed')}
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}