import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, Link, NavLink, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import CustomIcon from './CustomIcon';
import LanguageSwitcher from './LanguageSwitcher';
import ComingSoonModal from '../components/ComingSoonModal';

const ProtectedRoute = ({ session }) => {
  if (!session) {
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
};


const DefaultLayout = ({ session, setSession, supabase }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const navItems = [
    { to: '/home', icon: "M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6", label: t('app.sidebar.home') },
    { to: '/registry', icon: "M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7", label: t('app.sidebar.registries') },
    { to: '/user/balance', icon: "M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z", label: t('app.sidebar.balances') },
    { to: '/profile', icon: "M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z", label: t('app.sidebar.profile') },
    { to: '/website-builder', icon: "M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9", label: t('app.sidebar.website')},
    { to: '/guests', icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z", label: t('app.sidebar.guestList')},
    { to: '', icon: "M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z", label: t('app.sidebar.vendorMarketplace'), pill: t('app.sidebar.comingSoon') },
    { to: '/referrals', icon: "M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z", label: t('app.sidebar.referrals') },
  ];

  const isProtectedRoute = () => {
    const protectedPaths = ['/profile', '/onboarding', '/gift-catalog', '/home', '/registry', '/user/balance', '/website-builder', '/guests', '/referrals'];
    return protectedPaths.some(path => location.pathname.startsWith(path));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const Logo = ({ isWhite = false }) => (
    <div className="flex items-center">
      <svg className={`w-8 h-8 ${isWhite ? 'text-white' : 'text-gray-700'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2a10 10 0 0 1 0 20 10 10 0 0 1-7.5-16.5" strokeWidth="2" />
      </svg>
      <div className="flex items-center ml-2">
        <span className={`text-xl font-semibold ${isWhite ? 'text-white' : 'text-gray-800'}`}>{t('app.title')}</span>
        <span className={`ml-2 px-2 py-1 text-xs font-semibold ${isWhite ? 'text-gray-200 bg-gray-700' : 'text-gray-600 bg-gray-200'} rounded-full`}>Beta</span>
      </div>
    </div>
  );

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-grow">
          {session && isProtectedRoute() && (
            <aside
              className={`${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
              } fixed inset-y-0 left-0 z-50 w-72 bg-white shadow-lg transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static lg:inset-auto`}
            >
              <div className="flex items-center justify-between h-16 border-b border-gray-200 px-4">
                <Link to="/">
                  <Logo />
                </Link>
                <button
                  onClick={() => setSidebarOpen(false)}
                  className="text-gray-500 hover:text-gray-600 lg:hidden"
                >
                  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <nav className="mt-6 px-3">
                {navItems.map((item) => (
                  item.to ? (
                    <NavLink
                      key={item.to}
                      to={item.to}
                      className={({ isActive }) =>
                        `mt-2 group flex items-center px-3 py-3 text-base font-medium rounded-md transition-colors duration-200 ${
                          isActive
                            ? 'text-gray-900 bg-gray-100'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                        }`
                      }
                      onClick={() => setSidebarOpen(false)}
                    >
                      <CustomIcon d={item.icon} className="w-6 h-6 mr-3" />
                      <span> &nbsp;&nbsp;</span>
                      <span className="flex-grow">{item.label}</span>
                      {item.pill && (
                        <span className="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          {item.pill}
                        </span>
                      )}
                    </NavLink>
                  ) : (
                    <button
                      key={item.label}
                      className="mt-2 group flex items-center px-3 py-3 text-base font-medium rounded-md transition-colors duration-200 text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                      onClick={() => {
                        setSidebarOpen(false);
                        setIsComingSoonModalOpen(true);
                      }}
                    >
                      <CustomIcon d={item.icon} className="w-6 h-6 mr-3" />
                      <span> &nbsp;&nbsp;</span>
                      <span className="flex-grow">{item.label}</span>
                      {item.pill && (
                        <span className="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          {item.pill}
                        </span>
                      )}
                    </button>
                  )
                ))}
              </nav>
            </aside>
          )}
          {session && isProtectedRoute() && sidebarOpen && (
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-75 z-40 lg:hidden"
              onClick={() => setSidebarOpen(false)}
            ></div>
          )}
          <div className="flex flex-col flex-grow w-full">
            <header className="bg-white shadow-sm z-10">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex items-center">
                    {session && isProtectedRoute() ? (
                      <button
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        className="text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 lg:hidden"
                      >
                        <span className="sr-only">Open sidebar</span>
                        <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                      </button>
                    ) : (
                      <Link to="/">
                        <Logo />
                      </Link>
                    )}
                  </div>
                  <div className="flex items-center">
                    <LanguageSwitcher />
                    {!session ? (
                      <Link to="/login" className="ml-4 px-4 py-2 rounded-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 transition-colors duration-200">
                        {t('app.nav.login')}
                      </Link>
                    ) : (
                      <button
                        onClick={() => supabase.auth.signOut()}
                        className="ml-4 px-4 py-2 rounded-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 transition-colors duration-200"
                      >
                        {t('app.nav.signOut')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </header>
            <main className="flex-grow overflow-x-hidden overflow-y-auto bg-gradient-to-b from-gray-50 to-white">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
                <Outlet />
              </div>
            </main>
          </div>
        </div>
        <Footer />
      </div>
      <ComingSoonModal isOpen={isComingSoonModalOpen} onClose={() => setIsComingSoonModalOpen(false)} />
    </>
  );
};

export default DefaultLayout;