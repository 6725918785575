import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL, getSupabase } from '../config/supabase';
import { toast } from 'react-toastify';
import { Camera } from 'lucide-react';


const WebsiteConfig = ({ websiteData, handleInputChange, setWebsiteData, handleRegistryChange, handleAddRegistry, handleRemoveRegistry, handleAddQA, handleRemoveQA }) => {
  const { t } = useTranslation();
  const supabase = getSupabase();
  const [userRegistries, setUserRegistries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUserRegistries();
  }, []);

  const fetchUserRegistries = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        const { data, error } = await supabase
          .from('registries')
          .select('*')
          .eq('user_id', session.user.id);

        if (error) throw error;
        setUserRegistries(data);
      } catch (error) {
        console.error('Error fetching user registries:', error);
        toast.error(t('components.websiteConfig.registryFetchError'));
      }
    }
  };

  const uploadImage = async (file) => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('image', file);

        const response = await fetch(`${API_BASE_URL}/upload-image`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
          },
          body: formData
        });

        if (response.ok) {
          const imageData = await response.json();
          return imageData.imageUrl;
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to upload image');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error(t('components.websiteConfig.imageUploadError'));
        return null;
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error(t('components.websiteConfig.noSession'));
      return null;
    }
  };

  const handleImageUpload = async (e, section, field) => {
    const file = e.target.files[0];
    if (file) {
      setIsLoading(true);
      const imageUrl = await uploadImage(file);
      if (imageUrl) {
        if (section) {
          handleInputChange({ target: { name: field, value: imageUrl } }, section);
        } else {
          handleInputChange({ target: { name: field, value: imageUrl } });
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Wedding Name</h3>
        <input
          type="text"
          name="weddingName"
          value={websiteData.weddingName}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Enter your wedding name"
        />
      </div>

      {/* Wedding Date & Time */}
      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Wedding Date & Time</h3>
        <input
          type="date"
          name="weddingDate"
          value={websiteData.weddingDate}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <input
          type="time"
          name="weddingTime"
          value={websiteData.weddingTime}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
      </div>

      {/* Hero Section */}
      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Hero Section</h3>
        <div className="space-y-4">
          <div>
            <label htmlFor="heroSubtitle" className="block text-sm font-medium text-gray-700 mb-1">
              Hero Subtitle
            </label>
            <input
              type="text"
              id="heroSubtitle"
              name="heroSubtitle"
              value={websiteData.heroSubtitle}
              onChange={handleInputChange}
              placeholder="Enter hero subtitle"
              className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-gray-800 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="heroImage" className="block text-sm font-medium text-gray-700 mb-1">
              Hero Image
            </label>
            <div className="mt-1 flex items-center">
              <label className="w-full flex items-center px-4 py-2 bg-white text-gray-700 rounded-md border border-gray-300 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                {isLoading ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 mr-2"></div>
                ) : (
                  <Camera className="w-5 h-5 mr-2" />
                )}
                <span>{isLoading ? 'Uploading...' : 'Choose photo'}</span>
                <input
                  type="file"
                  id="heroImage"
                  name="heroImage"
                  accept="image/*"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setIsLoading(true);
                      try {
                        const imageUrl = await uploadImage(file);
                        if (imageUrl) {
                          setWebsiteData(prevData => ({
                            ...prevData,
                            heroImage: imageUrl
                          }));
                        }
                      } catch (error) {
                        console.error('Error uploading image:', error);
                        toast.error(t('components.websiteConfig.imageUploadError'));
                      } finally {
                        setIsLoading(false);
                      }
                    }
                  }}
                  className="hidden"
                  disabled={isLoading}
                />
              </label>
            </div>
          </div>
          {isLoading && (
            <div className="mt-4 flex justify-center">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
            </div>
          )}
          {websiteData.heroImage && (
            <div className="mt-4">
              <img src={websiteData.heroImage} alt="Hero" className="max-w-full h-auto max-h-64 rounded-lg shadow-sm" />
            </div>
          )}
        </div>
      </div>

      {/* Couple Names */}
      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Couple Names</h3>
        <input
          type="text"
          name="partner1"
          value={websiteData.coupleName.partner1}
          onChange={(e) => handleInputChange(e, 'coupleName')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Partner 1 Name"
        />
        <input
          type="text"
          name="partner2"
          value={websiteData.coupleName.partner2}
          onChange={(e) => handleInputChange(e, 'coupleName')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Partner 2 Name"
        />
      </div>

      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Event Details</h3>
        <h4 className="text-md font-medium mt-2">Ceremony</h4>
        <input
          type="date"
          name="date"
          value={websiteData.eventDetails.ceremony.date}
          onChange={(e) => handleInputChange(e, 'eventDetails', 'ceremony')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <input
          type="time"
          name="time"
          value={websiteData.eventDetails.ceremony.time}
          onChange={(e) => handleInputChange(e, 'eventDetails', 'ceremony')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <input
          type="text"
          name="venue"
          value={websiteData.eventDetails.ceremony.venue}
          onChange={(e) => handleInputChange(e, 'eventDetails', 'ceremony')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Ceremony Venue"
        />
        <input
          type="text"
          name="address"
          value={websiteData.eventDetails.ceremony.address}
          onChange={(e) => handleInputChange(e, 'eventDetails', 'ceremony')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Ceremony Address"
        />

        <h4 className="text-md font-medium mt-4">Reception</h4>
        <input
          type="date"
          name="date"
          value={websiteData.eventDetails.reception.date}
          onChange={(e) => handleInputChange(e, 'eventDetails', 'reception')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <input
          type="time"
          name="time"
          value={websiteData.eventDetails.reception.time}
          onChange={(e) => handleInputChange(e, 'eventDetails', 'reception')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <input
          type="text"
          name="venue"
          value={websiteData.eventDetails.reception.venue}
          onChange={(e) => handleInputChange(e, 'eventDetails', 'reception')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Reception Venue"
        />
        <input
          type="text"
          name="address"
          value={websiteData.eventDetails.reception.address}
          onChange={(e) => handleInputChange(e, 'eventDetails', 'reception')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Reception Address"
        />
      </div>

      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Our Story</h3>
        <input
          type="text"
          name="title"
          value={websiteData.story.title}
          onChange={(e) => handleInputChange(e, 'story')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Story Title"
        />
        <textarea
          name="content"
          value={websiteData.story.content}
          onChange={(e) => handleInputChange(e, 'story')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          rows="4"
          placeholder="Your story..."
        ></textarea>
        <h4 className="text-md font-medium mt-2">Photos</h4>
        <div className="grid grid-cols-3 gap-4 mt-2">
          {websiteData.story.photos.map((photo, index) => (
            <div key={index} className="relative">
              <img src={photo} alt={`Story photo ${index + 1}`} className="w-full h-32 object-cover rounded-md" />
              <button
                onClick={() => {
                  const newPhotos = [...websiteData.story.photos];
                  newPhotos.splice(index, 1);
                  setWebsiteData(prevData => ({
                    ...prevData,
                    story: {
                      ...prevData.story,
                      photos: newPhotos
                    }
                  }));
                }}
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
              >

              </button>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <label className="w-full flex items-center px-4 py-2 bg-white text-gray-700 rounded-md border border-gray-300 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            {isLoading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 mr-2"></div>
            ) : (
              <Camera className="w-5 h-5 mr-2" />
            )}
            <span>{isLoading ? 'Uploading...' : 'Choose photo'}</span>
            <input
              type="file"
              accept="image/*"
              onChange={async (e) => {
                const file = e.target.files[0];
                if (file) {
                  setIsLoading(true);
                  try {
                    const imageUrl = await uploadImage(file);
                    if (imageUrl) {
                      setWebsiteData(prevData => ({
                        ...prevData,
                        story: {
                          ...prevData.story,
                          photos: [...prevData.story.photos, imageUrl]
                        }
                      }));
                    }
                  } catch (error) {
                    console.error('Error uploading image:', error);
                    toast.error(t('components.websiteConfig.imageUploadError'));
                  } finally {
                    setIsLoading(false);
                  }
                }
              }}
              className="hidden"
              disabled={isLoading}
            />
          </label>
        </div>
        {isLoading && (
          <div className="mt-4 flex justify-center">
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
          </div>
        )}
      </div>

      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Registry</h3>
        <textarea
          name="message"
          value={websiteData.registry.message}
          onChange={(e) => handleInputChange(e, 'registry')}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          rows="3"
          placeholder="Registry message..."
        ></textarea>
        <h4 className="text-md font-medium mt-2">Registries</h4>
        {websiteData.registry.registries.map((registry, index) => (
          <div key={index} className="mt-2">
            <select
              value={registry.id || ''}
              onChange={(e) => {
                const selectedRegistry = userRegistries.find(r => r.id === e.target.value);
                if (selectedRegistry) {
                  handleRegistryChange(index, 'name', selectedRegistry.registry_name);
                  handleRegistryChange(index, 'url', selectedRegistry ? `/public/registry/${e.target.value}` : '');
                  handleRegistryChange(index, 'id', e.target.value);
                } else {
                  handleRegistryChange(index, 'name', '');
                  handleRegistryChange(index, 'url', '');
                  handleRegistryChange(index, 'id', '');
                }
              }}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              <option value="">Select a registry</option>
              {userRegistries.map((userRegistry) => (
                <option key={userRegistry.id} value={userRegistry.id}>
                  {userRegistry.registry_name}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={registry.name}
              onChange={(e) => handleRegistryChange(index, 'name', e.target.value)}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              placeholder="Registry Name"
            />
            <input
              type="text"
              value={registry.url}
              onChange={(e) => handleRegistryChange(index, 'url', e.target.value)}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              placeholder="Registry URL"
            />
            <button
              onClick={() => handleRemoveRegistry(index)}
              className="mt-1 px-2 py-1 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          onClick={handleAddRegistry}
          className="mt-2 px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
        >
          Add Registry
        </button>
      </div>

      <div className="bg-white shadow-sm rounded-lg p-4">
        <h3 className="text-lg font-medium mb-2">Q&A Section</h3>
        {Array.isArray(websiteData.qa) && websiteData.qa.map((item, index) => (
          <div key={index} className="mt-4">
            <input
              type="text"
              value={item.question}
              onChange={(e) => handleInputChange(e, 'qa', index, 'question')}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              placeholder="Question"
            />
            <textarea
              value={item.answer}
              onChange={(e) => handleInputChange(e, 'qa', index, 'answer')}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              rows="3"
              placeholder="Answer"
            ></textarea>
            <button
              onClick={() => handleRemoveQA(index)}
              className="mt-1 px-2 py-1 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          onClick={handleAddQA}
          className="mt-2 px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-200"
        >
          Add Q&A
        </button>
      </div>

      <div className="bg-white shadow-sm rounded-lg p-4 mt-4">
        <h3 className="text-lg font-medium mb-2">RSVP Settings</h3>
        <div className="flex items-center">
          <label htmlFor="showRsvp" className="mr-2">Show RSVP:</label>
          <input
            type="checkbox"
            id="showRsvp"
            checked={websiteData.showRsvp}
            onChange={(e) => handleInputChange({ target: { name: 'showRsvp', value: e.target.checked } })}
            className="form-checkbox h-5 w-5 text-gray-600"
          />
        </div>
      </div>
    </div>
  );
};
export default WebsiteConfig;
