

import { useState, useEffect } from 'react'
import { RSVPList } from './RSVPList'
import { useTranslation } from 'react-i18next'
import { getSupabase } from '../config/supabase'
import { API_BASE_URL } from '../config/supabase'
import { RSVPSeatingChart } from './SeatingChart'
import LoadingSpinner from './LoadingSpinner'
import { InviteGuests } from './InviteGuests'

export default function RSVPPage() {
  const { t } = useTranslation()
  const [guests, setGuests] = useState([])
  const [tables, setTables] = useState([])
  const [activeTab, setActiveTab] = useState('invite')
  const [weddingId, setWeddingId] = useState(null)
  const [rsvpData, setRsvpData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const supabase = getSupabase()

  useEffect(() => {
    const savedGuests = localStorage.getItem('guests')
    const savedTables = localStorage.getItem('tables')
    if (savedGuests) setGuests(JSON.parse(savedGuests))
    if (savedTables) setTables(JSON.parse(savedTables))
    fetchWeddingId()
  }, [])

  useEffect(() => {
    localStorage.setItem('guests', JSON.stringify(guests))
    localStorage.setItem('tables', JSON.stringify(tables))
  }, [guests, tables])

  const fetchWeddingId = async () => {
    setIsLoading(true)
    const { data: { session } } = await supabase.auth.getSession()
    if (session) {
      try {
        const response = await fetch(`${API_BASE_URL}/wedding-details`, {
          headers: { 'Authorization': `Bearer ${session.access_token}` }
        })
        if (!response.ok) {
          throw new Error('Failed to fetch wedding details')
        }
        const data = await response.json()
        setWeddingId(data.id)
        await fetchRSVPData(data.id)
      } catch (error) {
        console.error('Error fetching wedding details:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const fetchRSVPData = async (id) => {
    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .select('*')
        .eq('wedding_id', id)
      if (error) throw error
      setRsvpData(data)
    } catch (error) {
      console.error('Error fetching RSVPs:', error)
    }
  }

  const addGuest = (guest) => {
    setGuests([...guests, guest])
  }

  const addTable = () => {
    const newTable = {
      id: `table-${tables.length + 1}`,
      name: `Table ${tables.length + 1}`,
      capacity: 8,
      guests: []
    }
    setTables([...tables, newTable])
  }

  const handleGuestAdded = (newGuest) => {
    setRsvpData([...rsvpData, newGuest])
  }

  const tabs = [
    { id: 'invite', label: t('components.rsvpPage.inviteGuests'), icon: 'M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z' },
    { id: 'rsvp', label: t('components.rsvpPage.rsvpList'), icon: 'M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01' },
    { id: 'seating', label: t('components.rsvpPage.seatingChart'), icon: 'M4 6h16M4 12h16M4 18h16' },
  ]

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">{t('components.rsvpPage.weddingRSVP')}</h2>
      
      {tabs.length > 1 && (
        <div className="mb-6">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`${
                    activeTab === tab.id
                      ? 'border-gray-800 text-gray-800'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center mr-8`}
                >
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={tab.icon} />
                  </svg>
                  <span className="hidden sm:inline">{tab.label}</span>
                  <span className="sm:hidden">{tab.label.split(' ')[0]}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex items-center justify-center min-h-screen">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {activeTab === 'rsvp' && (
            <div>
              {weddingId && rsvpData.length > 0 ? (
                <RSVPList weddingId={weddingId} rsvpData={rsvpData} />
              ) : (
                <div className="text-center">No RSVP data available.</div>
              )}
            </div>
          )}

          {activeTab === 'seating' && (
            <div>
              <h3 className="text-xl font-semibold mb-4">{t('components.rsvpPage.seatingChart')}</h3>
              {weddingId && rsvpData.length > 0 ? (
                <RSVPSeatingChart weddingId={weddingId} rsvpData={rsvpData} />
              ) : (
                <div className="text-center">No seating data available.</div>
              )}
            </div>
          )}

          {activeTab === 'invite' && (
            <div>
              <h3 className="text-xl font-semibold mb-4">{t('components.rsvpPage.inviteGuests')}</h3>
              {weddingId ? (
                <InviteGuests weddingId={weddingId} onGuestAdded={handleGuestAdded} />
              ) : (
                <div className="text-center">Unable to invite guests at this time.</div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}