import React, { useState, useEffect} from 'react';
import { Menu, X, Calendar, Heart } from 'lucide-react';
import Logo from './Logo';
import { getSupabase } from '../config/supabase';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import RsvpTemplate from './RSVPTemplate';

const styles = {
  container: {
    minHeight: '100vh',
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: '#ffffff',
    color: '#333333',
  },
  header: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  headerContent: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: '1.5rem',
    fontWeight: 300,
    letterSpacing: '0.1em',
  },
  headerNav: {
    display: 'none',
    '@media (min-width: 768px)': {
      display: 'flex',
    },
  },
  headerLink: {
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    color: '#333333',
    textDecoration: 'none',
    marginLeft: '2rem',
  },
  menuButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  mobileNav: {
    backgroundColor: '#ffffff',
    padding: '1rem',
  },
  mobileNavLink: {
    display: 'block',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    color: '#333333',
    textDecoration: 'none',
    padding: '0.5rem 1rem',
  },
  hero: {
    height: '100vh',
    backgroundImage: "url('/placeholder.svg?height=1080&width=1920')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  heroContent: {
    textAlign: 'center',
    color: '#ffffff',
    position: 'relative',
    zIndex: 1,
  },
  heroTitle: {
    fontSize: '3rem',
    fontWeight: 300,
    letterSpacing: '0.1em',
    marginBottom: '1rem',
    '@media (min-width: 768px)': {
      fontSize: '4rem',
    },
  },
  heroSubtitle: {
    fontSize: '1.25rem',
    fontWeight: 300,
    letterSpacing: '0.1em',
    marginBottom: '2rem',
    '@media (min-width: 768px)': {
      fontSize: '1.5rem',
    },
  },
  heroDate: {
    fontSize: '1.5rem',
    fontWeight: 300,
    letterSpacing: '0.1em',
    '@media (min-width: 768px)': {
      fontSize: '2rem',
    },
  },
  section: {
    padding: '4rem 1rem',
  },
  sectionContent: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 300,
    letterSpacing: '0.1em',
    color: '#666666',
    textAlign: 'center',
    marginBottom: '3rem',
  },
  countdownTimer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
  },
  countdownItem: {
    textAlign: 'center',
    minWidth: '60px',
  },
  countdownNumber: {
    fontSize: '1.5rem',
    fontWeight: 300,
    color: '#333333',
  },
  countdownLabel: {
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    color: '#666666',
    marginTop: '0.5rem',
  },
  detailsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '2rem',
    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  detailsCard: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  detailsIcon: {
    color: '#666666',
    marginBottom: '1rem',
  },
  detailsCardTitle: {
    fontSize: '1.25rem',
    fontWeight: 300,
    letterSpacing: '0.1em',
    color: '#333333',
    marginBottom: '1rem',
  },
  detailsText: {
    color: '#666666',
    marginBottom: '0.5rem',
  },
  galleryGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1rem',
  },
  galleryImage: {
    width: '100%',
    height: '250px',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  registryContent: {
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center',
  },
  registryText: {
    color: '#666666',
    marginBottom: '2rem',
  },
  registryButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
  },
  registryButton: {
    backgroundColor: 'transparent',
    border: '1px solid #666666',
    color: '#666666',
    padding: '0.5rem 1rem',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: '#666666',
      color: '#ffffff',
    },
  },
  rsvpContent: {
    maxWidth: '600px',
    margin: '0 auto',
  },
  rsvpForm: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  label: {
    display: 'block',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    color: '#666666',
    marginBottom: '0.5rem',
  },
  input: {
    width: '100%',
    padding: '0.5rem',
    border: '1px solid #cccccc',
    fontSize: '1rem',
  },
  textarea: {
    width: '100%',
    padding: '0.5rem',
    border: '1px solid #cccccc',
    fontSize: '1rem',
    height: '100px',
    resize: 'vertical',
  },
  radioGroup: {
    display: 'flex',
    gap: '1rem',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.875rem',
    color: '#666666',
  },
  radioInput: {
    margin: 0,
  },
  submitButton: {
    width: '100%',
    backgroundColor: '#333333',
    color: '#ffffff',
    border: 'none',
    padding: '0.75rem',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#666666',
    },
  },
  footer: {
    backgroundColor: '#333333',
    color: '#ffffff',
    padding: '2rem 1rem',
  },
  footerContent: {
    maxWidth: '1200px',
    margin: '0 auto',
    textAlign: 'center',
  },
  footerTitle: {
    fontSize: '1.5rem',
    fontWeight: 300,
    letterSpacing: '0.1em',
    marginBottom: '1rem',
  },
  footerText: {
    fontSize: '0.875rem',
    marginBottom: '0.5rem',
  },
  
};

function CountdownTimer({ weddingDate, weddingTime, colors, t }) {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!weddingDate || !weddingTime) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      const [year, month, day] = weddingDate.split('-').map(Number);
      const [time, period] = weddingTime.split(' ');
      let [hours, minutes] = time ? time.split(':').map(Number) : [0, 0];
      
      // Convert to 24-hour format
      if (period) {
        if (period.toLowerCase() === 'pm' && hours !== 12) {
          hours += 12;
        } else if (period.toLowerCase() === 'am' && hours === 12) {
          hours = 0;
        }
      }

      // Use UTC to avoid timezone issues
      const weddingDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes));
      const now = new Date();

      const difference = weddingDateTime - now;

      if (difference > 0 && !isNaN(difference)) {
        const newTimeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
        setTimeLeft(newTimeLeft);
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [weddingDate, weddingTime]);

  return (
    <div style={styles.countdownTimer}>
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit} style={styles.countdownItem}>
          <div style={{...styles.countdownNumber, color: colors.primary}}>{value}</div>
          <div style={{...styles.countdownLabel, color: colors.primary}}>{t(`components.websiteTemplate.${unit}`)}</div>
        </div>
      ))}
    </div>
  );
}

export default function ModernMinimalistTemplate({ data }) {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const supabase = getSupabase();

  return (
    <div style={{...styles.container, fontFamily: `'${data.fonts.main}', sans-serif`}}>
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=${data.fonts.main}:wght@300;400;700&display=swap');
        
        body {
          margin: 0;
          padding: 0;
          font-family: '${data.fonts.main}', sans-serif;
        }

        @media (max-width: 768px) {
          .headerNav {
            display: none;
          }
          .mobileNav {
            display: block;
          }
          .heroContent {
            padding: 1rem;
          }
          .sectionContent {
            padding: 2rem 1rem;
          }
          .detailsGrid {
            flex-direction: column;
          }
          .galleryGrid {
            grid-template-columns: 1fr;
          }
          .registryButtons {
            flex-direction: column;
          }
          .countdownTimer {
            flex-wrap: wrap;
            justify-content: center;
          }
          .countdownItem {
            margin: 0.5rem;
          }
        }
      `}</style>

      {/* Header */}
      <header style={{...styles.header, backgroundColor: data.colors.background}}>
        <div style={styles.headerContent}>
          <h1 style={{...styles.headerTitle, color: data.colors.primary}}>
            {`${data.weddingName}`}
          </h1>
          <nav className="headerNav" style={styles.headerNav}>
            <a href="#home" style={{...styles.headerLink, color: data.colors.primary}}>{t('components.websiteTemplate.home')}</a>
            <a href="#details" style={{...styles.headerLink, color: data.colors.primary}}>{t('components.websiteTemplate.details')}</a>
            <a href="#photos" style={{...styles.headerLink, color: data.colors.primary}}>{t('components.websiteTemplate.photos')}</a>
            <a href="#registry" style={{...styles.headerLink, color: data.colors.primary}}>{t('components.websiteTemplate.registry')}</a>
            <a href="#rsvp" style={{...styles.headerLink, color: data.colors.primary}}>{t('components.websiteTemplate.rsvp')}</a>
          </nav>
          <button style={styles.menuButton} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <X /> : <Menu />}
          </button>
        </div>
        {isMenuOpen && (
          <nav className="mobileNav" style={{...styles.mobileNav, backgroundColor: data.colors.background}}>
            <a href="#home" style={{...styles.mobileNavLink, color: data.colors.primary}}>{t('components.websiteTemplate.home')}</a>
            <a href="#details" style={{...styles.mobileNavLink, color: data.colors.primary}}>{t('components.websiteTemplate.details')}</a>
            <a href="#photos" style={{...styles.mobileNavLink, color: data.colors.primary}}>{t('components.websiteTemplate.photos')}</a>
            <a href="#registry" style={{...styles.mobileNavLink, color: data.colors.primary}}>{t('components.websiteTemplate.registry')}</a>
            <a href="#rsvp" style={{...styles.mobileNavLink, color: data.colors.primary}}>{t('components.websiteTemplate.rsvp')}</a>
          </nav>
        )}
      </header>

      {/* Hero Section */}
      <section id="home" style={{...styles.hero, backgroundImage: `url(${data.heroImage})`, height: '80vh'}}>
        <div className="heroContent" style={styles.heroContent}>
          <h1 style={{...styles.heroTitle, color: data.colors.secondary}}>
            {`${data.weddingName}`}
          </h1>
          <p style={{...styles.heroSubtitle, color: data.colors.secondary}}>{data.heroSubtitle}</p>
          <p style={{...styles.heroDate, color: data.colors.secondary}}>
            {data.weddingDate}
          </p>
        </div>
      </section>

     {/* Countdown Timer */}
     <section style={{...styles.section, backgroundColor: data.colors.background}}>
        <div className="sectionContent" style={styles.sectionContent}>
          <h2 style={{...styles.sectionTitle, color: data.colors.primary}}>{t('components.websiteTemplate.countdownTitle')}</h2>
          {data.weddingDate && data.weddingTime && (
            <CountdownTimer
              weddingDate={data.weddingDate}
              weddingTime={data.weddingTime}
              colors={data.colors}
              t={t}
            />
          )}
        </div>
      </section>

      {/* Event Details */}
      <section id="details" style={{...styles.section, backgroundColor: data.colors.accent}}>
        <div className="sectionContent" style={styles.sectionContent}>
          <h2 style={{...styles.sectionTitle, color: data.colors.primary}}>{t('components.websiteTemplate.eventDetails')}</h2>
          <div className="detailsGrid" style={styles.detailsGrid}>
            <div style={styles.detailsCard}>
              <div style={{...styles.detailsIcon, color: data.colors.primary}}>
                <Calendar />
              </div>
              <h3 style={{...styles.detailsCardTitle, color: data.colors.primary}}>{t('components.websiteTemplate.ceremony')}</h3>
              <p style={styles.detailsText}>{`${data.eventDetails.ceremony.date} at ${data.eventDetails.ceremony.time}`}</p>
              <p style={styles.detailsText}>{data.eventDetails.ceremony.venue}</p>
              <p style={styles.detailsText}>{data.eventDetails.ceremony.address}</p>
            </div>
            <div style={styles.detailsCard}>
              <div style={{...styles.detailsIcon, color: data.colors.primary}}>
                <Heart />
              </div>
              <h3 style={{...styles.detailsCardTitle, color: data.colors.primary}}>{t('components.websiteTemplate.reception')}</h3>
              <p style={styles.detailsText}>{`${data.eventDetails.reception.date} at ${data.eventDetails.reception.time}`}</p>
              <p style={styles.detailsText}>{data.eventDetails.reception.venue}</p>
              <p style={styles.detailsText}>{data.eventDetails.reception.address}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Photo Gallery */}
      <section id="photos" style={{...styles.section, backgroundColor: data.colors.background}}>
        <div className="sectionContent" style={styles.sectionContent}>
          <h2 style={{...styles.sectionTitle, color: data.colors.primary, textAlign: 'center', marginBottom: '1.5rem'}}>{data.story.title}</h2>
          <div style={{maxWidth: '800px', margin: '0 auto', padding: '0 1rem', marginBottom: '3rem'}}>
            <p style={{...styles.storyContent, color: data.colors.primary, textAlign: 'center', lineHeight: '1.6', fontSize: '1.1rem'}}>{data.story.content}</p>
          </div>
          <div className="galleryGrid" style={{...styles.galleryGrid, marginTop: '2rem'}}>
            {data.story.photos.map((photo, index) => (
              <img
                key={index}
                src={photo || `/placeholder.svg?height=300&width=400&text=Photo ${index + 1}`}
                alt={`Couple photo ${index + 1}`}
                style={{...styles.galleryImage, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'}}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Registry */}
      <section id="registry" style={{...styles.section, backgroundColor: data.colors.accent}}>
        <div className="sectionContent" style={styles.registryContent}>
          <h2 style={{...styles.sectionTitle, color: data.colors.primary}}>{t('components.websiteTemplate.giftRegistry')}</h2>
          <p style={{...styles.registryText, color: data.colors.primary}}>
            {data.registry.message}
          </p>
          <div className="registryButtons" style={styles.registryButtons}>
            {data.registry.registries.map((registry, index) => (
              <a key={index} href={registry.url} target="_blank" rel="noopener noreferrer" style={{...styles.registryButton, backgroundColor: data.colors.primary, color: data.colors.secondary}}>
                {registry.name}
              </a>
            ))}
          </div>
        </div>
      </section>

      {/* Q&A Section */}
      {data.qa && data.qa.length > 0 && (
        <section id="qa" style={{...styles.section, backgroundColor: data.colors.background}}>
          <div className="sectionContent" style={styles.sectionContent}>
            <h2 style={{...styles.sectionTitle, color: data.colors.primary, textAlign: 'center', marginBottom: '2rem'}}>Q&A</h2>
            <div style={{maxWidth: '800px', margin: '0 auto', padding: '0 1rem'}}>
              {data.qa.map((item, index) => (
                <div key={index} style={{marginBottom: '2rem'}}>
                  <h3 style={{...styles.qaQuestion, color: data.colors.primary, marginBottom: '0.5rem'}}>{item.question}</h3>
                  <p style={{...styles.qaAnswer, color: data.colors.primary}}>{item.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {/* RSVP Form */}
      {data.showRsvp && (
        <RsvpTemplate data={data} colors={data.colors} styles={styles} />
      )}

      {/* Footer */}
      <footer style={{...styles.footer, backgroundColor: data.colors.primary}}>
        <div className="sectionContent" style={styles.footerContent}>
          <h2 style={{...styles.footerTitle, color: data.colors.secondary}}>
            {`${data.coupleName.partner1} & ${data.coupleName.partner2}`}
          </h2>
          <p style={{...styles.footerText, color: data.colors.secondary}}>{data.weddingDate}</p>
          <p style={{...styles.footerText, color: data.colors.secondary, marginBottom: '50px'}}>
            {data.eventDetails.ceremony.venue}, {data.eventDetails.ceremony.address}
          </p>
          
          <div style={{marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <p style={{...styles.footerText, color: data.colors.secondary, marginRight: '10px'}}>Powered by</p>
            <a href="/" style={{textDecoration: 'none'}}>
              <Logo isWhite={true} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}