import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate, Outlet, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { SpeedInsights } from '@vercel/speed-insights/react';
import LandingPage from './components/LandingPage';
import SpecificRegistry from './components/SpecificRegistry';
import GuestRegistryView from './components/GuestRegistryView';
import Login from './components/Login';
import Signup from './components/Signup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegistryOrders from './components/RegistryOrders';
import Profile from './components/Profile';
import Registry from './components/Registry';
import CheckoutPage from './components/CheckoutPage';
import BankTransferCheckout from './components/BankTransferCheckout';
import UserBalance from './components/UserBalance';
import { getSupabase } from './config/supabase';
import LoadingSpinner from './components/LoadingSpinner';
import HomePage from './components/HomePage';
import About from './components/About';
import ReceivedGifts from './components/ReceivedGifts';
import ThankYouLetters from './components/ThankYouLetters';
import CheckoutSuccessPage from './components/CheckoutSuccess';
import GiftCatalog from './components/GiftCatalog';
import ComingSoonModal from './components/ComingSoonModal';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import WebsiteBuilder from './components/WebsiteBuilder';
import WeddingPage from './components/PublicWebsiteView';
import ProtectedRoute from './components/ProtectedRoute';
import DefaultLayout from './components/DefaultLayout';
import BlankLayout from './components/BlankLayout';
import PricingPage from './components/Pricing';
import ConfirmPayoutInfo from './components/ConfirmPayoutInfo';
import RSVPPage from './components/RSVPPage';
import AdminGiftManagement from './components/AdminGiftManagement';
import AdminPayoutManagement from './components/AdminPayoutManagement';
import ReferralsPage from './components/ReferralsPage';
import OAuthCallback from './components/OAuthCallback'; // You'll need to create this component



// Scroll to top component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);
  const supabase = getSupabase();
  const { t } = useTranslation();
   // Create and Install Session Replay Plugin
  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);

  useEffect(() => {
    // Initialize Amplitude
    amplitude.init('03b563b4098e992b824f7e22cf5b0c81', {
      "autocapture": true,
      "sessionReplay": {
        enabled: true,
        sampling: 1.0 // Capture 100% of sessions
      }
    });
    
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
      if (session) {
        // Set user ID in Amplitude
        amplitude.setUserId(session.user.id);
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoading(false);
      if (session) {
        // Set user ID in Amplitude when auth state changes
        amplitude.setUserId(session.user.id);
      } else {
        // Clear user ID if logged out
        amplitude.setUserId(null);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // Track page views
    amplitude.track('Page View', { path: window.location.pathname });

    // Set up global error tracking
    const originalOnError = window.onerror;
    window.onerror = (message, source, lineno, colno, error) => {
      // Track the error in Amplitude
      amplitude.track('Error', {
        message,
        source,
        lineno,
        colno,
        stack: error && error.stack
      });

      // Call the original onerror handler if it exists
      if (typeof originalOnError === 'function') {
        return originalOnError(message, source, lineno, colno, error);
      }
      return false;
    };

    // Set up unhandled promise rejection tracking
    window.addEventListener('unhandledrejection', (event) => {
      amplitude.track('Unhandled Promise Rejection', {
        reason: event.reason && event.reason.toString(),
        stack: event.reason && event.reason.stack
      });
    });

    return () => {
      window.onerror = originalOnError;
      window.removeEventListener('unhandledrejection', () => {});
    };
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route element={<DefaultLayout session={session} setSession={setSession} supabase={supabase} />}>
          <Route path="/" element={session ? <Navigate to="/home" /> : <LandingPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/public/registry/:id" element={<GuestRegistryView />} />
          <Route path="/checkout/:id" element={<CheckoutPage />} />
          <Route path="/bank-transfer/:orderId" element={<BankTransferCheckout />} />
          <Route path="/checkout/success/:id" element={<CheckoutSuccessPage />} />
          <Route path="/login" element={!session ? <Login setSession={setSession} /> : <Navigate to="/home" />} />
          <Route path="/signup" element={!session ? <Signup setSession={setSession} /> : <Navigate to="/home" />} />
          <Route path="/auth/callback" element={<OAuthCallback setSession={setSession} />} />

          <Route element={<ProtectedRoute session={session} />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/gift-catalog/:id" element={<GiftCatalog/>} />
            <Route path="/gift-catalog" element={<GiftCatalog/>} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/registry" element={<Registry />} />
            <Route path="/registry/:id" element={<SpecificRegistry />} />
            <Route path="/registry/:id/orders" element={<RegistryOrders />} />
            <Route path="/registry/:id/gifts" element={<ReceivedGifts />} />
            <Route path="/registry/:id/letters" element={<ThankYouLetters />} />
            <Route path="/user/balance" element={<UserBalance />} />
            <Route path="/website-builder" element={<WebsiteBuilder />} />
            <Route path="/confirm-payout-info/:token" element={<ConfirmPayoutInfo />} />
            <Route path="/guests" element={<RSVPPage />} />
            <Route path="/admin/gift-management" element={<AdminGiftManagement />} />
            <Route path="/admin/payout-management" element={<AdminPayoutManagement />} />
            <Route path="/referrals" element={<ReferralsPage />} />
          </Route>
        </Route>

        <Route element={<BlankLayout />}>
          <Route path="/wedding/:id" element={<WeddingPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      <ComingSoonModal isOpen={isComingSoonModalOpen} setIsOpen={setIsComingSoonModalOpen} />
      <SpeedInsights />
    </Router>
  );
}

export default App;