import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSupabase } from '../config/supabase';
import { Trash2, Edit2 } from 'lucide-react';

export function InviteGuests({ weddingId, onGuestAdded }) {
  const [guest, setGuest] = useState({ full_name: '', email: '', cellphone: '' });
  const [invitedGuests, setInvitedGuests] = useState([]);
  const [editingGuest, setEditingGuest] = useState(null);
  const { t } = useTranslation();
  const supabase = getSupabase();
  const formRef = useRef(null);

  useEffect(() => {
    fetchInvitedGuests();
  }, []);

  const fetchInvitedGuests = async () => {
    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .select('*')
        .eq('wedding_id', weddingId);

      if (error) throw error;
      setInvitedGuests(data);
    } catch (error) {
      console.error('Error fetching invited guests:', error);
      toast.error(t('components.inviteGuests.fetchError'));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGuest(prevGuest => ({ ...prevGuest, [name]: value }));
  };

  const inviteGuest = async (e) => {
    e.preventDefault();
    
    if (!guest.full_name || !guest.email) {
      toast.error(t('components.inviteGuests.noValidGuests'));
      return;
    }

    try {
      const { data, error } = await supabase
        .from('wedding_rsvps')
        .insert({
          ...guest,
          wedding_id: weddingId,
          rsvp_status: 'to_be_invited',
          rsvp_date: new Date().toISOString(),
        });

      if (error) throw error;

      toast.success(t('components.inviteGuests.inviteSuccess'));
      if (data && Array.isArray(data)) {
        onGuestAdded(data[0]);
      }
      setGuest({ full_name: '', email: '', cellphone: '' });
      fetchInvitedGuests(); // Refresh the list of invited guests
    } catch (error) {
      console.error('Error inviting guest:', error);
      toast.error(t('components.inviteGuests.inviteError'));
    }
  };

  const deleteInvitation = async (guestId) => {
    try {
      const { error } = await supabase
        .from('wedding_rsvps')
        .delete()
        .eq('id', guestId);

      if (error) throw error;

      toast.success(t('components.inviteGuests.deleteSuccess'));
      fetchInvitedGuests(); // Refresh the list of invited guests
    } catch (error) {
      console.error('Error deleting invitation:', error);
      toast.error(t('components.inviteGuests.deleteError'));
    }
  };

  const startEditing = (guest) => {
    setEditingGuest(guest);
    setGuest(guest);
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const cancelEditing = () => {
    setEditingGuest(null);
    setGuest({ full_name: '', email: '', cellphone: '' });
  };

  const updateGuest = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('wedding_rsvps')
        .update(guest)
        .eq('id', editingGuest.id);

      if (error) throw error;

      toast.success(t('components.inviteGuests.updateSuccess'));
      setEditingGuest(null);
      setGuest({ full_name: '', email: '', cellphone: '' });
      fetchInvitedGuests(); // Refresh the list of invited guests
    } catch (error) {
      console.error('Error updating guest:', error);
      toast.error(t('components.inviteGuests.updateError'));
    }
  };

  return (
    <div className="mt-4 px-2 sm:px-4 md:px-6 lg:px-8">
      <form ref={formRef} onSubmit={editingGuest ? updateGuest : inviteGuest} className="space-y-4">
        <div className="border p-4 rounded-md">
          <div className="mb-3">
            <label htmlFor="full_name" className="block text-sm font-medium text-gray-700">
              {t('components.inviteGuests.fullName')}
            </label>
            <input
              type="text"
              name="full_name"
              id="full_name"
              value={guest.full_name}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t('components.inviteGuests.email')}
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={guest.email}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="cellphone" className="block text-sm font-medium text-gray-700">
              {t('components.inviteGuests.cellphone')}
            </label>
            <input
              type="tel"
              name="cellphone"
              id="cellphone"
              value={guest.cellphone}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm"
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between">
          <button
            type="submit"
            className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
          >
            {editingGuest ? t('components.inviteGuests.updateButton') : t('components.inviteGuests.inviteButton')}
          </button>
          {editingGuest && (
            <button
              type="button"
              onClick={cancelEditing}
              className="w-full sm:w-auto py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
            >
              {t('components.inviteGuests.cancelButton')}
            </button>
          )}
        </div>
      </form>

      <div className="mt-8">
        <h4 className="text-lg font-semibold mb-4">{t('components.inviteGuests.invitedGuestsList')}</h4>
        {invitedGuests.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {invitedGuests.map((invitedGuest) => (
              <li key={invitedGuest.id} className="py-4">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                  <div className="mb-2 sm:mb-0">
                    <h3 className="text-sm font-medium">{invitedGuest.full_name}</h3>
                    <p className="text-sm text-gray-500">{invitedGuest.email}</p>
                    {invitedGuest.cellphone && (
                      <p className="text-sm text-gray-500">{invitedGuest.cellphone}</p>
                    )}
                  </div>
                  <div className="flex justify-start sm:justify-end">
                    <button
                      onClick={() => startEditing(invitedGuest)}
                      className="mr-2 p-2 text-blue-500 hover:text-blue-700 hover:bg-blue-100 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                      aria-label={t('components.inviteGuests.editInvitation')}
                    >
                      <Edit2 size={18} />
                    </button>
                    <button
                      onClick={() => deleteInvitation(invitedGuest.id)}
                      className="p-2 text-red-500 hover:text-red-700 hover:bg-red-100 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
                      aria-label={t('components.inviteGuests.deleteInvitation')}
                    >
                      <Trash2 size={18} />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-sm text-gray-500">{t('components.inviteGuests.noInvitedGuests')}</p>
        )}
      </div>
    </div>
  );
}